import { useAppContext } from '@/context/Context';
import Link from 'next/link';
import { useState } from 'react';



const HeaderTopFour = ({
  gapSpaceBetween,
  bgColor,
  flexDirection,
  btnText,
  btnClass,
  container,
}) =>  {
  const { toggle, setToggle } = useAppContext();

  const [topBarTimer, setTopBarTimer] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setTopBarTimer(true);
  //   }, 3000); // 3000 milliseconds = 3 seconds

  //   return () => clearTimeout(timer); // Clear the timer on component unmount
  // }, []);

  return (
    <>
      <div
        className={`rbt-header-top rbt-header-top-1 variation-height-50 ${gapSpaceBetween} border-top-bar-primary-color rbt-border-bottom d-none d-xl-block ${
          !toggle ? "d-none" : ""
        }`}
      >
        <div className={`${container}`}>
          <div className={`rbt-header-sec align-items-center ${flexDirection}`}>
            <div className="rbt-header-sec-col rbt-header-left">
              <div className="rbt-header-content">
                <div className="header-info">
                  <ul className="rbt-information-list">
                    <li>
                      <Link href={`Tel: +1 587-349-1110`}>
                        <i className="feather-phone"></i>+1 587-349-1110
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="rbt-separator"></div>
                <div className="header-info">
                  <ul className="social-share-transparent">
                    <li>
                      <Link href="https://www.facebook.com/GDCollege9/">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link href="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>*/}
                    <li>
                      <Link href="https://ca.linkedin.com/company/gdcollege">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li> 
                    <li>
                      <Link href="https://www.instagram.com/gd_college/">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link href="#">
                        <i className="fab fa-skype"></i>
                      </Link>
                    </li> */}
                    <li>
                      <Link href="https://www.youtube.com/@gdcollege">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                    <li className='tiktok-icon'>
                      <Link href="https://www.tiktok.com/@gdcollege3">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
                        </svg>
                      </Link>
                    </li> 
                  </ul>
                </div>
              </div>
            </div>

            <div className="rbt-header-sec-col rbt-header-right">
              <div className="rbt-header-content">
                {/* <div className="header-info">
                  <ul className="rbt-secondary-menu">
                    <li>
                      <Link href="/my-account">My Account</Link>
                    </li>
                    <li>
                      <Link href="#">FAQ</Link>
                    </li>
                    <li>
                      <Link href="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link href="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link href="#">Terms & Condition</Link>
                    </li>
                  </ul>
                </div> */}
                {/* <div className="header-info" onClick={() => setToggle(!toggle)}>
                  <div className="header-right-btn d-flex">
                    <Link className={`rbt-btn ${btnClass}`} href="#">
                      <span data-text={`Subscribe Now`}>Subscribe Now</span>
                    </Link>
                  </div>
                </div> */}
                {/* <div className="rbt-separator"></div> */}
                <div className="header-info" onClick={() => setToggle(!toggle)}>
                  <div className="header-right-btn d-flex">
                    <Link className={`rbt-btn ${btnClass}`} href="/contact" style={{background: 'white', color: 'black'}}>
                      {/* <span data-text={`${btnText}`}>{btnText}</span> */}
                      <span data-text={`Ask Your Query`}>Ask Your Query</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </>
  );
};

export default HeaderTopFour